<template>

  <FocusTrap>
    <div class="card" id="myform">

<!--    <div class="card-header header-elements-inline bg-secondary">-->
<!--      <h5 class="card-title">Employee Information</h5>-->
<!--      <div class="header-elements">-->
<!--        <div class="list-icons">-->
<!--          <a class="list-icons-item" data-action="reload"></a>-->
<!--          <a class="list-icons-item" data-action="remove" @click="closeWindow"></a>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

    <div class="card-header header-elements-inline" style="background-color: whitesmoke; padding: 10px;">
      <h5 v-if="emp.id == 0" class="card-title">Employee Creation</h5>
      <h5 v-if="emp.id > 0" class="card-title"> Employee : {{emp.id}}  Updation</h5>
      <div class="header-elements">
        <div class="list-icons">
          <a class="list-icons-item" data-action="reload" @click="loadData" ></a>
          <a class="list-icons-item" data-action="remove" @click="closeWindow"></a>
        </div>
      </div>
    </div>



      <div class="card-body" style="padding-top: 15px;">
      <div class="form-group row">
        <label class="col-md-2 col-form-label font-weight-semibold">Name:</label>
        <div class="col-md-2">
          <input ref="txtcode" id="txtcode" type="text" class="form-control" placeholder="Emp Code here..." maxlength="10" v-if="emp"  v-model="emp.code" autofocus  autocomplete="off" >
        </div>
        <div class="col-md-8">
          <input  ref="txtemp" type="text" class="form-control" placeholder="Enter Name here..." maxlength="100" v-if="emp" v-model="emp.name" autocomplete="off">
        </div>
      </div>

      <div class="form-group row">
        <label class="col-md-2 col-form-label font-weight-semibold">Designation:</label>
        <div class="col-md-10">
          <select ref="cmbDesign" class="form-control" v-if="emp" v-model="emp.design_id">
            <option value="1">-</option>
            <option v-for="dsgn in designations" v-bind:value="dsgn.id">
              {{ dsgn.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="form-group row">

        <label class="col-md-2 col-form-label font-weight-semibold">Processes:</label>
        <div class="col-md-10">
          <div class="row">
            <div class="col-md-6">

              <div class="form-check ">
                <label class="form-check-label">
                  <div class="uniform-checker border-success text-primary">
                    <input type="checkbox" class="form-check-input-styled-primary" v-model="emp.extrusion" >
                  </div>
                  Extrusion
                </label>
              </div>

              <div class="form-check ">
                <label class="form-check-label">
                  <div class="uniform-checker border-success text-success">
                    <input type="checkbox" class="form-check-input-styled-success" v-model="emp.stentering">
                  </div>
                  Stentering
                </label>
              </div>

            </div>

            <div class="col-md-6">

              <div class="form-check ">
                <label class="form-check-label">
                  <div class="uniform-checker border-success text-danger">
                    <input type="checkbox" class="form-check-input-styled-danger" v-model="emp.warping" >
                  </div>
                  Warping
                </label>
              </div>



            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="card-footer">
      <div class="text-right">
        <button type="button" class="btn btn-primary" @click="saveDocument">Save<i class="icon-paperplane ml-2"></i>
        </button>
      </div>
    </div>

  </div>
  </FocusTrap>
</template>

<script>
  import moment from 'moment-timezone'
  import { userService } from '@/store/auth-header.js'

  export default {
    name: 'EmployeeForm',
    component: {},
    data () {
      return {
        emp: {"id":0,"status":"Active","name":"","design_id":1,"extrusion":false,"stentering":false,"warping":false,"designation":{"id":1,"status":"Active","name":"","min_wage":0}},
        designations: []
      }
    },
    props: {
      myemp: {
        type: Object,
        default: () => JSON.parse('{"id":0,"status":"Active","name":"","design_id":1,"extrusion":false,"stentering":false,"warping":false,"designation":{"id":1,"status":"Active","name":"","min_wage":0}}')
      }
    },
    beforeMount () {
      this.emp =  JSON.parse(JSON.stringify(this.myemp));
      // this.emp.depts = [];
      //
      // this.myemp.depts.forEach(dept =>{
      //   this.emp.depts.push(dept);
      //   switch (dept) {
      //     case 307:
      //       this.$data.warping = true;
      //       break;
      //     case 308:
      //       this.$data.extrusion = true;
      //       break;
      //     case 309:
      //       this.$data.extrusion = true;
      //       break;
      //   }
      // });

    },
    created () {

    },
    mounted () {
      $('input[type=checkbox]').uniform();
      this.loadDesignations();
      $('#txtcode').focus();
    },
    methods: {
      closeWindow () {
        this.$emit('employee_window_closed');
      },
      loadDesignations () {
        const self = this;

        const requestOptions = {
          method: 'GET',
          mode: 'cors',
          headers: userService.authHeader()
        }

        self.$data.designations = []
        fetch(`${process.env.VUE_APP_ROOT_API}api/designations`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            if (_.isArray(resp.data)) {
              self.$data.designations = resp.data
            }
          } else {
            swal({ title: 'Oops!', text: resp.msg, type: 'error' })
          }
        }).catch(function (err) {
          swal({ title: 'Oh noes!', text: err.toString(), type: 'error' })
        })

      },
      saveDocument () {
        const self = this;
        self.$data.emp.design_id = parseInt(self.$data.emp.design_id)

        if (self.$data.emp.code.trim().length < 1) {
          alert('Invalid Employee Code')
          return
        } else if (self.$data.emp.name.trim().length < 1) {
          alert('Invalid Name')
          return
        } else if (self.$data.emp.design_id < 1) {
          alert('Invalid Employee Designation')
          return
        }


        const requestOptions = {
          method: (self.$data.emp.id == 0 ? 'POST' : 'PUT'),
          mode: 'cors',
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.emp)
        }

        $('#myform').block({
          msg: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        })

        fetch(`${process.env.VUE_APP_ROOT_API}api/employee`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            swal({ title: 'Success!', text: resp.msg, type: 'success', onClose: () => { $('#txtcode').focus()}, timer:1500 })
            self.emp = JSON.parse('{"id":0,"status":"Active", "code":"", "name":"","design_id":1,"extrusion":false,"stentering":false,"warping":false,"designation":{"id":1,"status":"Active","name":"","min_wage":0}}')
            self.$emit('employee_saved', resp.data);

          } else {
            swal({ title: 'Oops!', text: resp.msg, type: 'error', timer:3000 })
          }
        }).catch(function (err) {
          swal({ title: 'Oh noes!', text: err.toString(), type: 'error', timer:3000 })
        }).finally(function () {
          $('#myform').unblock()
        })
      }
    }
  }
</script>

<style scoped>
  input:focus {
    background: #feff00;

  }

  textarea:focus {
    background: #feff00;

  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    color: #0a0a0a!important;
  }
</style>
